import React, { useState } from "react";
import axios from "axios";
import { PortableText } from "@portabletext/react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "gatsby";

export default function Footer({ data }) {
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("https://leads.civsav.com/lawebdesign", {
        data: state,
      })
      .then(() => {
        setState({
          ...state,
          firstName: "",
          lastName: "",
          email: "",
          message: "",
        });
        setLoading(false);
        toast.success("Your request has been submitted");
      })
      .catch(() => {
        setLoading(false);
        toast.error("Something went wrong!");
      });
  };
  return (
    <div>
      <section id="contact" className="contact">
        <div className="section-title">
          <p>Let's have a chat</p>
          <div className="row">
            <div className="col-lg-12">
              <form className="email-form" onSubmit={handleSubmit}>
                <p className="form-head">
                  Name <span> *</span>
                </p>
                <div className="row">
                  <div className="col form-group">
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      id="fname"
                      required
                      onChange={handleChange}
                      value={state.firstName}
                      aria-label="fname"
                    />
                    <p className="input-bottom">First Name</p>
                  </div>
                  <div className="col form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      id="lname"
                      required
                      onChange={handleChange}
                      value={state.lastName}
                      aria-label="lname"
                    />
                    <p className="input-bottom">Last Name</p>
                  </div>
                </div>
                <p className="form-head">
                  Email <span> *</span>
                </p>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    required
                    onChange={handleChange}
                    value={state.email}
                    aria-label="email"
                  />
                </div>
                <p className="form-head">
                  Message <span> *</span>
                </p>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    required
                    onChange={handleChange}
                    value={state.message}
                    aria-label="message"
                  ></textarea>
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" disabled={loading}>
                    {loading ? "Please wait..." : "SUBMIT"}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div id="c-footer">
            <p>
              <a href={`tel:+${data?.contact}`}>{data?.contact}</a>
            </p>
          </div>
          <div className="base-content">
            {data?._rawDescription && (
              <PortableText value={data?._rawDescription} />
            )}
          </div>
          <div className="footer-links">
            {data?.footer?.menu?.map((item, index) => {
              if (item.type === "internal") {
                return <Link to={item.link}>{item.label}</Link>;
              } else {
                return (
                  <a href={item.link} target="_blank">
                    {item.label}
                  </a>
                );
              }
            })}
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  );
}
