import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Footer from "../footer";

export default function Layout({ children }) {
  const { sanitySitesettings } = useStaticQuery(
    graphql`
      query {
        sanitySitesettings {
          contact
          _rawDescription
          footer {
            menu {
              type
              link
              label
            }
          }
        }
      }
    `
  );

  return (
    <>
      <main>
        {children}
        <Footer data={sanitySitesettings} />
      </main>
    </>
  );
}
