import React from "react";
import Layout from "./src/components/layout";
import "./node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./src/styles/main.scss";

export const wrapPageElement = ({ element, props }) => {
  if (props.location.pathname === "/") {
    return <>{element}</>;
  }
  return <Layout {...props}>{element}</Layout>;
};
